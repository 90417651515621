import React, { Component } from "react"
import styled from "styled-components"
import axios from "axios"
import LogoImg from "../assets/logo.jpg"
import BgImg from "../assets/bgimg.jpg"
import SmImg from "../assets/smimg.jpg"
import { responsive } from "../common/responsive"

const BackgroundImage = styled.div`
  ${responsive(
    "background",
    `url(${SmImg}) no-repeat center center fixed`,
    `url(${SmImg}) no-repeat center center fixed`,
    `url(${BgImg}) no-repeat center center fixed`,
    `url(${BgImg}) no-repeat center center fixed`,
  )}
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* Full-screen */
  height: 100%;
  width: 100%;
  /* Add a font */
  color: white;
  font-family: "Libre Franklin", sans-serif;
  /* Set the font-size to 25 pixels */
  font-size: 25px;
`

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(100, 100, 100, 0.5);
  height: 100%;
  width: 100%;
`

const Logo = styled.img`
  margin-left: 7%;
  height: 100px;
  width: 100px;
`

const Middle = styled.div`
  text-align: center;
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  background-color: #211e1a;
`

const CTAText = styled.p`
  ${responsive("font-size", "20px", "20px", "20px", "20px")};
  margin: 10px 0;
  text-align: center;
`

const CTAForm = styled.div`
  display: flex;
  justify-content: center;
`

const ErrorMessage = styled.p`
  color: #ee2050;
  font-size: 15px;
  display: flex;
  justify-content: center;
`

const SuccessMessage = styled.p`
  color: #0c720c;
  font-size: 15px;
  display: flex;
  justify-content: center;
`

const EmailInput = styled.input`
  -webkit-appearance: none;
  border: 0;
  font-family: inherit;
  font-size: 16px;
  padding: 0 5px;
  font-weight: 500;
  ${props =>
    props.error
      ? "border: 2px solid #ee2050"
      : "border-bottom: 2px solid #c8ccd4"}
  background: none;
  border-radius: 0;
  color: white;
  margin-right: 40px;
  transition: all 0.15s ease;

  &:hover {
    background: #555;
  }
  &:not(:placeholder-shown) {
    + span {
      color: #5a667f;
      transform: translateY(-26px) scale(0.75);
    }
  }
  &:focus {
    background: none;
    outline: none;
    border-bottom: 2px solid #ee2050;
  }
`

const CTAButton = styled.button`
  width: 100px;
  height: 40px;
  background-color: #211e1a;
  color: white;
  border: 2px solid #ee2050;
  border-radius: 7px;

  &:hover {
    background-color: #ee2050;
  }
`

const emailValidation = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

class Landing extends Component {
  constructor() {
    super()
    this.state = {
      error: "",
      email: "",
      success: "",
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleInputChange(e) {
    const value = e.target.value
    this.setState({ email: value })
  }
  handleSubmit() {
    if (emailValidation(this.state.email)) {
      axios({
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        url:
          "https://lxob7g8760.execute-api.us-east-1.amazonaws.com/Prod/emaillist",
        data: {
          email: this.state.email,
        },
      })
        .then(({ data }) => {
          const { message } = data
          if (message === "Success!") {
            this.setState({
              error: "",
              success: "Success! Thanks for registering",
            })
          } else {
            this.setState({
              error: message,
              success: "",
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.setState({
            error:
              "It looks like we are having issues on our end. Please try again!",
            success: "",
          })
        })
    } else {
      this.setState({ error: "That's not an email!", success: "" })
    }
  }
  render() {
    const { error, success } = this.state
    return (
      <BackgroundImage>
        <Overlay>
          <Logo src={LogoImg} alt="Aktiv Logo" />
          <Middle>
            <h1>We are working hard to get you Aktiv!</h1>
          </Middle>
          <Bottom>
            <CTAText>
              Want to receive news about Aktiv?! Release dates, events, etc?
              Sign up here!
            </CTAText>
            <CTAForm>
              <EmailInput
                id="email"
                onChange={this.handleInputChange}
                placeholder="Enter your email!"
                error={error}
              />
              <CTAButton onClick={this.handleSubmit}>Subscribe!</CTAButton>
            </CTAForm>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {success && <SuccessMessage>{success}</SuccessMessage>}
          </Bottom>
        </Overlay>
      </BackgroundImage>
    )
  }
}

export default Landing
