// Imports


// Plugins
const plugins = [{
  location: "/Users/mish/Dev/Projects/Aktiv/AktivWeb/plugins/ie11-polyfills-plugin",
  plugins: [],
  hooks: {}
},
{
  location: "/Users/mish/Dev/Projects/Aktiv/AktivWeb",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins