/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
import Main from "./pages"

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
`

function App() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Aktiv</title>
        <meta
          name="description"
          content="We are working hard to get you Aktiv!"
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <GlobalStyle />
      <Main />
    </React.Fragment>
  )
}

export default App
