import { css } from "styled-components"

export const responsive = (field, sm, md, lg, xl) => css`
  ${field}: ${sm}};
  ${sm !== md &&
    `
  @media(min-width: 599px) {
      ${field}: ${md};
  }
  `}
  ${md !== lg &&
    `
  @media(min-width: 1200px) {
      ${field}: ${lg};
  }
  `}
  ${lg !== xl &&
    `
  @media(min-width: 1800px) {
      ${field}: ${xl};
  }
  `}
`
